.swiper-container {
  overflow: hidden;
}

.swiper-slide {
  width: auto !important;
  flex-shrink: 0;
}

.swiper-button-prev {
  height: 100% !important;
  width: 60px !important;
  top: 22px !important;
  left: 0 !important;
  background: linear-gradient(to right, #040e17, transparent);
  color: white !important;
}

.dark .swiper-button-prev{
  background: linear-gradient(to right, #f9f9f9, transparent);
  color: #7d7d7d !important;

}

.swiper-button-prev::after {
  font-size: 25px !important;
  font-weight: 500;
}

.swiper-button-next {
  height: 100% !important;
  width: 60px !important;
  top: 22px !important;
  right: 0 !important;
  background: linear-gradient(to left, #040e17, transparent);
  color: white !important;
}

.dark .swiper-button-next{
  background: linear-gradient(to left, #f9f9f9, transparent);
  color: #7d7d7d !important;
}

.swiper-button-next::after {
  font-size: 25px !important;
  font-weight: 500;
}

