@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: 3px  !important
}



.agent-visualizer > .lk-audio-bar {
  width: 30px !important;
}

@media (max-width: 600px) {
  .agent-visualizer > .lk-audio-bar {
    width: 25px !important;
    height: 25px;
  }

  
}

.agent-visualizer{
  background-color: transparent !important;
}

.agent-visualizer > .lk-highlighted {
  background-color: #0B6DFF !important;
}
.lk-audio-bar-visualizer[data-lk-va-state=speaking]>.lk-audio-bar, .lk-audio-bar-visualizer>.lk-audio-bar.lk-highlighted, .lk-audio-bar-visualizer>[data-lk-highlighted=true]{
  background-color: #0B6DFF !important;
}

.lk-agent-control-bar{
  border: none !important;
  margin-right: 0 !important;

}

.lk-device-menu{
  z-index: 1000 !important;
  position: absolute !important;
}

.lk-button-group{
  border: 1px solid #0B6DFF !important;
  border-radius: 8px !important;
}


.lk-button{
  background-color: #060f17 !important ;
}

.lk-device-menu{
  background-color: #060f17 !important ;
}

.lk-disconnect-button{
  border-radius: 100% !important;
  height: 40px !important;
  width: 40px !important;
  transition: all 0.5s ease-out !important; 
}

.lk-agent-control-bar {
  @apply border-t-0 p-0 h-min mr-4;
}

.lk-disconnect-button {
  @apply h-[36px] hover:bg-[#6b221a] hover:text-[white] bg-[#31100c] border-[#6b221a];
}


.custom-transition {
  @apply transition-all ease-out duration-300;
}


