/* .card{
    background: radial-gradient(at 0.4143851143973214% 92.49826158796039%, #3f3f46, #131316)
} */


  
  .zoom-in {
    animation: zoomIn 8s ease-in-out infinite;
  }

  @keyframes zoomIn {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }